import React from 'react';

const OutsourcingTeamworkOriented = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.4206 25.8972C22.2374 25.8901 22.0635 25.8123 21.9339 25.6792C21.8043 25.5461 21.7286 25.3675 21.7217 25.1794V23.7725C21.7217 23.5821 21.7954 23.3996 21.9264 23.265C22.0575 23.1304 22.2352 23.0547 22.4206 23.0547C22.6059 23.0547 22.7836 23.1304 22.9147 23.265C23.0458 23.3996 23.1195 23.5821 23.1195 23.7725V25.2943C23.0927 25.4642 23.0072 25.6183 22.8789 25.7289C22.7506 25.8396 22.5881 25.8994 22.4206 25.8972Z"
      fill="#00FFFF"
    />
    <path
      d="M25.663 25.9259C25.4776 25.9259 25.2999 25.8502 25.1689 25.7156C25.0378 25.581 24.9641 25.3985 24.9641 25.2081V23.7725C24.9641 23.5821 25.0378 23.3996 25.1689 23.265C25.2999 23.1304 25.4776 23.0547 25.663 23.0547C25.8484 23.0547 26.0264 23.1304 26.1575 23.265C26.2885 23.3996 26.3619 23.5821 26.3619 23.7725V25.3231C26.3351 25.4929 26.2499 25.647 26.1216 25.7577C25.9934 25.8683 25.8305 25.9281 25.663 25.9259Z"
      fill="#00FFFF"
    />
    <path
      d="M37.5721 22.5082C36.0421 22.5082 34.5747 21.8859 33.4903 20.7775C32.4059 19.6691 31.7928 18.1646 31.7854 16.5933V11.9132C31.8872 10.412 32.5397 9.00645 33.6111 7.98C34.6825 6.95355 36.0929 6.38281 37.5581 6.38281C39.0232 6.38281 40.434 6.95355 41.5054 7.98C42.5768 9.00645 43.2293 10.412 43.3311 11.9132V16.5933C43.3311 18.162 42.7243 19.6665 41.6443 20.7757C40.5643 21.8849 39.0994 22.5082 37.5721 22.5082ZM37.5721 7.43429C36.997 7.4305 36.4271 7.5435 35.8948 7.7669C35.3625 7.9903 34.8783 8.31936 34.4704 8.73566C34.0625 9.15196 33.7385 9.64734 33.5176 10.1926C33.2967 10.738 33.1832 11.3226 33.1832 11.9132V16.5933C33.2712 17.723 33.7702 18.7771 34.581 19.5459C35.3917 20.3146 36.4546 20.7417 37.5581 20.7417C38.6615 20.7417 39.7247 20.3146 40.5355 19.5459C41.3463 18.7771 41.8453 17.723 41.9333 16.5933V11.9132C41.9333 10.7253 41.4738 9.58617 40.656 8.74617C39.8381 7.90618 38.7287 7.43429 37.5721 7.43429Z"
      fill="#FCFCFD"
    />
    <path
      d="M28.7105 26.815C28.6153 26.8173 28.5208 26.7979 28.4338 26.7582C28.3468 26.7185 28.2693 26.6592 28.2072 26.585C28.1403 26.5206 28.087 26.4429 28.0505 26.3565C28.0141 26.2701 27.9956 26.177 27.9956 26.0828C27.9956 25.9886 28.0141 25.8951 28.0505 25.8087C28.087 25.7223 28.1403 25.6446 28.2072 25.5802C28.3152 25.4599 28.4372 25.3539 28.5706 25.2644C29.5383 24.485 30.6517 23.9179 31.8415 23.5989L35.811 22.4506C35.8991 22.4243 35.9914 22.4159 36.0827 22.4261C36.1739 22.4364 36.2626 22.4649 36.343 22.5102C36.5056 22.6018 36.6259 22.7558 36.6778 22.9385C36.7212 23.1249 36.693 23.3214 36.5993 23.487C36.5057 23.6527 36.3535 23.7751 36.1745 23.8288L32.233 24.9773C31.2152 25.2393 30.2626 25.7186 29.4374 26.3842L29.1299 26.6425C29.0106 26.7426 28.8642 26.8028 28.7105 26.815Z"
      fill="#FCFCFD"
    />
    <path
      d="M43.8066 37.2087H35.0285C34.8431 37.2087 34.6651 37.133 34.534 36.9984C34.4029 36.8638 34.3296 36.6813 34.3296 36.4909C34.3296 36.3005 34.4029 36.1176 34.534 35.983C34.6651 35.8484 34.8431 35.7731 35.0285 35.7731H43.8066C44.548 35.7731 45.2589 35.4704 45.7831 34.9319C46.3074 34.3934 46.6021 33.6633 46.6021 32.9019V28.1068C46.6021 27.59 46.1827 26.9871 45.3999 26.3554C44.5712 25.6955 43.6199 25.2168 42.6043 24.9485L38.7744 23.8287C38.6778 23.8126 38.5854 23.7758 38.5035 23.7207C38.4215 23.6657 38.3518 23.5935 38.2987 23.509C38.2457 23.4245 38.2104 23.3298 38.1953 23.2304C38.1803 23.131 38.1858 23.0295 38.2114 22.9325C38.237 22.8354 38.2823 22.7448 38.3441 22.6668C38.4059 22.5888 38.483 22.5251 38.5704 22.4797C38.6577 22.4342 38.7531 22.4083 38.8509 22.4032C38.9487 22.3982 39.0465 22.4143 39.1379 22.4506L42.9958 23.5704C44.1834 23.8954 45.2954 24.4616 46.2664 25.2356C46.7503 25.5455 47.1585 25.9658 47.459 26.4637C47.7596 26.9616 47.9448 27.5237 47.9999 28.1068V32.9019C48.0036 33.4685 47.8974 34.0302 47.688 34.5544C47.4786 35.0787 47.1701 35.555 46.7799 35.9557C46.3898 36.3564 45.926 36.6732 45.4156 36.8883C44.9052 37.1034 44.3583 37.2125 43.8066 37.2087Z"
      fill="#FCFCFD"
    />
    <path
      d="M36.0627 23.7718C35.8774 23.7718 35.6996 23.6961 35.5686 23.5615C35.4375 23.4268 35.3638 23.2443 35.3638 23.0539V21.7046C35.3432 21.6003 35.3454 21.4927 35.3703 21.3895C35.3952 21.2862 35.4419 21.1897 35.5075 21.1073C35.573 21.025 35.6555 20.9587 35.7491 20.9131C35.8427 20.8676 35.9451 20.8438 36.0487 20.8438C36.1523 20.8438 36.2544 20.8676 36.348 20.9131C36.4416 20.9587 36.5241 21.025 36.5896 21.1073C36.6552 21.1897 36.7023 21.2862 36.7271 21.3895C36.752 21.4927 36.7542 21.6003 36.7336 21.7046V23.0539C36.7338 23.2394 36.6638 23.4178 36.5388 23.5516C36.4137 23.6855 36.2432 23.7643 36.0627 23.7718Z"
      fill="#FCFCFD"
    />
    <path
      d="M38.9142 23.7708C38.7289 23.7708 38.5508 23.6951 38.4197 23.5605C38.2887 23.4259 38.2153 23.2433 38.2153 23.053V21.6748C38.2153 21.4845 38.2887 21.3019 38.4197 21.1673C38.5508 21.0327 38.7289 20.957 38.9142 20.957C39.0996 20.957 39.2773 21.0327 39.4084 21.1673C39.5394 21.3019 39.6131 21.4845 39.6131 21.6748V23.053C39.6131 23.2433 39.5394 23.4259 39.4084 23.5605C39.2773 23.6951 39.0996 23.7708 38.9142 23.7708Z"
      fill="#FCFCFD"
    />
    <path
      d="M37.4326 29.2835C36.3164 29.1192 35.3066 28.5141 34.6176 27.5973C33.9286 26.6806 33.6145 25.5242 33.7423 24.3735C33.7423 24.0864 33.7423 23.7707 33.7423 23.4836C33.7558 23.3888 33.7878 23.2978 33.8361 23.2158C33.8845 23.1339 33.9483 23.0628 34.0238 23.0066C34.0994 22.9504 34.1851 22.9101 34.276 22.8885C34.3669 22.8669 34.4612 22.8641 34.5531 22.8804C34.7339 22.9088 34.8969 23.0091 35.0066 23.1594C35.1164 23.3097 35.1641 23.4982 35.1401 23.6844C35.1262 23.9139 35.1262 24.144 35.1401 24.3735C35.1401 26.2685 36.2026 27.8479 37.4326 27.8479C38.6627 27.8479 39.7248 26.2685 39.7248 24.3735C39.7387 24.144 39.7387 23.9139 39.7248 23.6844C39.6968 23.5857 39.6899 23.482 39.7044 23.3802C39.7188 23.2784 39.7542 23.181 39.8084 23.0946C39.8627 23.0081 39.9344 22.9349 40.0186 22.8794C40.1029 22.8239 40.198 22.7874 40.2971 22.7728C40.3962 22.7583 40.4973 22.7659 40.5933 22.7949C40.6894 22.8239 40.7781 22.874 40.8537 22.9414C40.9293 23.0088 40.9899 23.0918 41.0315 23.1853C41.0731 23.2789 41.0946 23.3808 41.0946 23.4836C41.1223 23.7796 41.1223 24.0775 41.0946 24.3735C41.223 25.5198 40.9131 26.6726 40.2299 27.5886C39.5467 28.5046 38.5437 29.1124 37.4326 29.2835Z"
      fill="#FCFCFD"
    />
    <path
      d="M39.4453 12.6889C38.9144 12.6908 38.3894 12.5732 37.9076 12.3444C37.4033 12.0992 36.9556 11.7469 36.5937 11.3108C36.4023 11.0353 36.1267 10.833 35.8109 10.7363C35.112 10.5641 34.1336 11.1385 33.0153 12.3731C32.8847 12.5046 32.7093 12.5782 32.5263 12.5782C32.3434 12.5782 32.1676 12.5046 32.037 12.3731C31.9693 12.3062 31.9157 12.2257 31.879 12.1369C31.8422 12.0481 31.823 11.9526 31.823 11.8561C31.823 11.7597 31.8422 11.6642 31.879 11.5754C31.9157 11.4866 31.9693 11.4065 32.037 11.3395C33.5466 9.6455 34.8324 8.98485 36.1464 9.27197C36.7334 9.43268 37.2537 9.78574 37.6281 10.2772C37.8707 10.57 38.1648 10.8135 38.4949 10.995C39.1379 11.2821 40.1442 11.4831 42.2408 10.2484C42.3199 10.1894 42.4104 10.1478 42.506 10.1268C42.6016 10.1058 42.7004 10.1058 42.7961 10.1265C42.8917 10.1472 42.982 10.1882 43.0612 10.247C43.1405 10.3059 43.2069 10.3814 43.2561 10.4682C43.3052 10.555 43.3363 10.6512 43.3468 10.751C43.3574 10.8509 43.3473 10.9519 43.3175 11.0476C43.2877 11.1432 43.2386 11.2316 43.1735 11.3066C43.1084 11.3815 43.0288 11.4415 42.9397 11.4829C41.898 12.1926 40.6929 12.6086 39.4453 12.6889Z"
      fill="#FCFCFD"
    />
    <path
      d="M10.679 22.5082C9.14907 22.5082 7.68134 21.8859 6.5969 20.7775C5.51247 19.6691 4.89972 18.1646 4.89233 16.5933V11.9132C4.99411 10.412 5.64663 9.00645 6.71805 7.98C7.78946 6.95355 9.19983 6.38281 10.665 6.38281C12.1302 6.38281 13.5409 6.95355 14.6123 7.98C15.6837 9.00645 16.3362 10.412 16.438 11.9132V16.5933C16.438 17.37 16.2889 18.1392 15.9995 18.8568C15.7101 19.5744 15.286 20.2265 14.7512 20.7757C14.2164 21.325 13.5815 21.7606 12.8828 22.0578C12.1841 22.3551 11.4353 22.5082 10.679 22.5082ZM10.679 7.43429C10.1039 7.4305 9.53407 7.5435 9.00173 7.7669C8.46939 7.9903 7.98526 8.31936 7.57733 8.73566C7.1694 9.15196 6.84546 9.64734 6.62454 10.1926C6.40363 10.738 6.2901 11.3226 6.29011 11.9132V16.5933C6.37811 17.723 6.87712 18.7771 7.6879 19.5459C8.49867 20.3146 9.56155 20.7417 10.665 20.7417C11.7685 20.7417 12.8317 20.3146 13.6425 19.5459C14.4532 18.7771 14.9522 17.723 15.0402 16.5933V11.9132C15.0402 11.325 14.9274 10.7427 14.7082 10.1993C14.489 9.65588 14.1679 9.1621 13.7629 8.74617C13.358 8.33025 12.8772 8.00006 12.3481 7.77496C11.819 7.54987 11.2517 7.43429 10.679 7.43429Z"
      fill="#FCFCFD"
    />
    <path
      d="M12.9155 37.2088H4.13738C3.0375 37.2012 1.98522 36.7471 1.21009 35.9456C0.434965 35.1441 -2.47707e-05 34.06 1.05834e-09 32.9303V28.1356C0.0551244 27.5525 0.239996 26.9904 0.540548 26.4925C0.841099 25.9946 1.24926 25.5744 1.73323 25.2644C2.70421 24.4904 3.81651 23.9239 5.00416 23.5989L8.97364 22.4506C9.06175 22.4243 9.15404 22.4159 9.24528 22.4261C9.33652 22.4364 9.42484 22.4649 9.50532 22.5102C9.66784 22.6018 9.78853 22.7558 9.84043 22.9385C9.88378 23.1249 9.85561 23.3214 9.76194 23.487C9.66827 23.6527 9.51614 23.7751 9.33708 23.8288L5.39524 24.9773C4.37747 25.2393 3.4249 25.7186 2.59968 26.3842C1.81692 27.0159 1.39778 27.6188 1.39778 28.1356V32.9303C1.39778 33.6918 1.69218 34.4223 2.21645 34.9607C2.74072 35.4992 3.45191 35.8016 4.19334 35.8016H12.9714C13.1568 35.8016 13.3345 35.8772 13.4656 36.0118C13.5966 36.1465 13.6703 36.329 13.6703 36.5194C13.6669 36.6162 13.6444 36.7114 13.6041 36.799C13.5639 36.8867 13.5068 36.9653 13.4362 37.0297C13.3657 37.0941 13.2831 37.143 13.1936 37.1737C13.1041 37.2045 13.0095 37.2166 12.9155 37.2088Z"
      fill="#FCFCFD"
    />
    <path
      d="M19.2057 26.7887C19.0327 26.7889 18.8648 26.7279 18.7303 26.6163L18.4788 26.3867C17.6501 25.7268 16.6988 25.2481 15.6832 24.9798L11.8533 23.86C11.7627 23.8367 11.6775 23.795 11.6029 23.7373C11.5282 23.6796 11.4655 23.6069 11.4186 23.5239C11.3717 23.4409 11.3416 23.3492 11.3298 23.254C11.3181 23.1588 11.3248 23.0622 11.35 22.9698C11.4019 22.787 11.5226 22.633 11.6851 22.5415C11.8476 22.4499 12.0388 22.4286 12.2168 22.4819L16.0747 23.6013C17.2623 23.9264 18.3746 24.4929 19.3456 25.2669L19.6531 25.5252C19.7811 25.6594 19.8527 25.8399 19.8527 26.0278C19.8527 26.2157 19.7811 26.3959 19.6531 26.53C19.5998 26.6024 19.5324 26.6626 19.4555 26.707C19.3785 26.7515 19.2935 26.7795 19.2057 26.7887Z"
      fill="#FCFCFD"
    />
    <path
      d="M9.1694 23.7718C8.98404 23.7718 8.80599 23.6961 8.67492 23.5615C8.54386 23.4268 8.47051 23.2443 8.47051 23.0539V21.7046C8.4499 21.6003 8.45213 21.4927 8.477 21.3895C8.50186 21.2862 8.54863 21.1897 8.61418 21.1073C8.67973 21.025 8.7622 20.9587 8.85579 20.9131C8.94938 20.8676 9.05183 20.8438 9.15541 20.8438C9.25899 20.8438 9.3611 20.8676 9.45469 20.9131C9.54828 20.9587 9.63075 21.025 9.6963 21.1073C9.76185 21.1897 9.80896 21.2862 9.83382 21.3895C9.85869 21.4927 9.86092 21.6003 9.84031 21.7046V23.0539C9.84045 23.2394 9.77053 23.4178 9.64545 23.5516C9.52037 23.6855 9.34987 23.7643 9.1694 23.7718Z"
      fill="#FCFCFD"
    />
    <path
      d="M24.1258 24.3755C22.4302 24.368 20.8062 23.673 19.6073 22.4415C18.4083 21.2101 17.7312 19.5422 17.7239 17.8007V12.5749C17.7239 10.831 18.3985 9.15867 19.5991 7.9256C20.7996 6.69253 22.4279 6 24.1258 6C25.8237 6 27.452 6.69253 28.6526 7.9256C29.8531 9.15867 30.5278 10.831 30.5278 12.5749V17.8007C30.5204 19.5422 29.8434 21.2101 28.6444 22.4415C27.4454 23.673 25.8214 24.368 24.1258 24.3755ZM24.1258 7.43561C22.7987 7.43561 21.5258 7.97678 20.5873 8.94062C19.6489 9.90446 19.1217 11.2118 19.1217 12.5749V17.8007C19.1217 19.1638 19.6489 20.4707 20.5873 21.4346C21.5258 22.3984 22.7987 22.9399 24.1258 22.9399C25.453 22.9399 26.7259 22.3984 27.6643 21.4346C28.6027 20.4707 29.13 19.1638 29.13 17.8007V12.5749C29.13 11.2118 28.6027 9.90446 27.6643 8.94062C26.7259 7.97678 25.453 7.43561 24.1258 7.43561Z"
      fill="#00FFFF"
    />
    <path
      d="M31.0587 41.0007H16.6895C15.4758 40.9932 14.314 40.4945 13.4558 39.6131C12.5975 38.7317 12.1121 37.5384 12.1047 36.2919V30.8939C12.1693 30.2509 12.377 29.632 12.7115 29.0844C13.0459 28.5368 13.4983 28.0753 14.0339 27.7357C15.1163 26.878 16.3503 26.2441 17.6679 25.8693L22.141 24.6061C22.2273 24.5801 22.3176 24.5721 22.4069 24.5827C22.4962 24.5932 22.5825 24.6219 22.6608 24.6675C22.739 24.713 22.8077 24.7744 22.8625 24.8476C22.9172 24.9209 22.9569 25.0046 22.9795 25.094C23.0314 25.2768 23.0106 25.4735 22.9215 25.6404C22.8324 25.8074 22.6821 25.931 22.5041 25.9843L18.0593 27.2474C16.9139 27.5657 15.8406 28.1122 14.9003 28.8555C14.2573 29.3723 13.5025 30.1474 13.5025 30.8939V36.2919C13.5025 36.7217 13.585 37.1474 13.7451 37.5445C13.9053 37.9416 14.1403 38.3026 14.4362 38.6065C14.7321 38.9104 15.0832 39.1514 15.4699 39.3159C15.8565 39.4804 16.271 39.5651 16.6895 39.5651H31.0587C31.8991 39.5575 32.7026 39.2094 33.2943 38.5963C33.8859 37.9833 34.2178 37.155 34.2177 36.2919V30.8939C34.2177 30.1474 33.4629 29.3723 32.8479 28.8555C31.9076 28.1122 30.8343 27.5657 29.6889 27.2474L25.3557 26.013C25.1777 25.9597 25.0278 25.8357 24.9387 25.6688C24.8495 25.5019 24.8287 25.3055 24.8806 25.1228C24.9325 24.94 25.0529 24.786 25.2154 24.6945C25.3779 24.6029 25.5691 24.5816 25.7471 24.6349L30.0524 25.8693C31.3808 26.2378 32.6251 26.8721 33.7144 27.7357C34.2419 28.0816 34.6868 28.5453 35.0159 29.0921C35.345 29.6389 35.55 30.2546 35.6155 30.8939V36.2919C35.6155 37.5357 35.1363 38.7291 34.2826 39.6114C33.4288 40.4936 32.2698 40.9931 31.0587 41.0007Z"
      fill="#00FFFF"
    />
    <path
      d="M12.0207 23.7708C11.8353 23.7708 11.6576 23.6951 11.5265 23.5605C11.3955 23.4259 11.3218 23.2433 11.3218 23.053V21.6748C11.3218 21.4845 11.3955 21.3019 11.5265 21.1673C11.6576 21.0327 11.8353 20.957 12.0207 20.957C12.206 20.957 12.3841 21.0327 12.5151 21.1673C12.6462 21.3019 12.7196 21.4845 12.7196 21.6748V23.053C12.7196 23.2433 12.6462 23.4259 12.5151 23.5605C12.3841 23.6951 12.206 23.7708 12.0207 23.7708Z"
      fill="#FCFCFD"
    />
    <path
      d="M10.5393 29.2835C9.42314 29.1192 8.41331 28.5141 7.72429 27.5973C7.03528 26.6806 6.72116 25.5242 6.84898 24.3735C6.84898 24.0864 6.84898 23.7707 6.84898 23.4836C6.86253 23.3888 6.89446 23.2978 6.94282 23.2158C6.99118 23.1339 7.05495 23.0628 7.13051 23.0066C7.20607 22.9504 7.29182 22.9101 7.3827 22.8885C7.47358 22.8669 7.56788 22.8641 7.6598 22.8804C7.84063 22.9088 8.00322 23.0091 8.11298 23.1594C8.22275 23.3097 8.27081 23.4982 8.24676 23.6844C8.23291 23.9139 8.23291 24.144 8.24676 24.3735C8.24676 26.2685 9.30926 27.8479 10.5393 27.8479C11.7694 27.8479 12.8315 26.2685 12.8315 24.3735C12.8454 24.144 12.8454 23.9139 12.8315 23.6844C12.8035 23.5857 12.7966 23.482 12.811 23.3802C12.8254 23.2784 12.8609 23.181 12.9151 23.0946C12.9694 23.0081 13.041 22.9349 13.1253 22.8794C13.2096 22.8239 13.3047 22.7874 13.4038 22.7728C13.5029 22.7583 13.604 22.7659 13.7 22.7949C13.7961 22.8239 13.8847 22.874 13.9604 22.9414C14.036 23.0088 14.0966 23.0918 14.1382 23.1853C14.1798 23.2789 14.2013 23.3808 14.2013 23.4836C14.229 23.7796 14.229 24.0775 14.2013 24.3735C14.3297 25.5198 14.0198 26.6726 13.3366 27.5886C12.6533 28.5046 11.6504 29.1124 10.5393 29.2835Z"
      fill="#FCFCFD"
    />
    <path
      d="M23.9857 31.986C22.7615 31.8138 21.6498 31.1615 20.8837 30.1659C20.1175 29.1702 19.7565 27.9086 19.8763 26.6455C19.8498 26.3111 19.8498 25.9751 19.8763 25.6407C19.904 25.455 20.0016 25.2876 20.1479 25.1749C20.2943 25.0621 20.4778 25.0128 20.6591 25.0375C20.8422 25.0726 21.0051 25.1787 21.1144 25.3336C21.2236 25.4886 21.2707 25.6806 21.2461 25.8702C21.2322 26.1285 21.2322 26.3873 21.2461 26.6455C21.2461 28.8277 22.4482 30.6366 24.0417 30.6366C25.6351 30.6366 26.8372 28.8277 26.8372 26.6455C26.8511 26.3873 26.8511 26.1285 26.8372 25.8702C26.8213 25.7758 26.824 25.6793 26.8451 25.586C26.8661 25.4927 26.905 25.4046 26.9597 25.327C27.0144 25.2494 27.0837 25.1835 27.1635 25.1339C27.2432 25.0842 27.3319 25.0514 27.4242 25.0375C27.6055 25.0128 27.7894 25.0621 27.9357 25.1749C28.0821 25.2876 28.1793 25.455 28.207 25.6407C28.2335 25.9751 28.2335 26.3111 28.207 26.6455C28.3248 27.9262 27.9488 29.2033 27.1594 30.202C26.3699 31.2007 25.2302 31.8412 23.9857 31.986Z"
      fill="#00FFFF"
    />
    <path
      d="M26.2223 13.3495C25.622 13.3574 25.0279 13.2245 24.4853 12.9608C23.9427 12.697 23.4659 12.3094 23.0913 11.8276C22.8486 11.5056 22.5176 11.2656 22.1409 11.1386C21.3301 10.9376 20.2119 11.598 18.9259 13.0337C18.7953 13.1652 18.6195 13.2387 18.4365 13.2387C18.2536 13.2387 18.0782 13.1652 17.9475 13.0337C17.8807 12.9692 17.8273 12.8912 17.7909 12.8048C17.7545 12.7184 17.7356 12.6252 17.7356 12.5311C17.7356 12.4369 17.7545 12.3437 17.7909 12.2573C17.8273 12.1709 17.8807 12.0932 17.9475 12.0288C19.6249 10.1338 21.1344 9.38711 22.4763 9.70294C23.1246 9.89422 23.7002 10.2851 24.1256 10.8228C24.3963 11.1668 24.7407 11.442 25.132 11.6268C25.8588 11.9713 27.0051 12.2009 29.4092 10.794C29.5669 10.7208 29.7454 10.7104 29.9102 10.7646C30.075 10.8188 30.2143 10.9338 30.3013 11.0874C30.3882 11.241 30.4165 11.4224 30.3808 11.5963C30.3451 11.7702 30.2481 11.9243 30.1081 12.0288C28.9453 12.8055 27.6067 13.2604 26.2223 13.3495Z"
      fill="#00FFFF"
    />
    <path
      d="M12.5241 12.6889C11.9929 12.6946 11.4671 12.5769 10.9864 12.3444C10.4822 12.0992 10.0344 11.7469 9.6726 11.3108C9.48113 11.0353 9.2056 10.833 8.88976 10.7363C8.19087 10.5641 7.24038 11.1385 6.0942 12.3731C5.96356 12.5046 5.78812 12.5782 5.60518 12.5782C5.42225 12.5782 5.24647 12.5046 5.11582 12.3731C5.04818 12.3062 4.99457 12.2257 4.95782 12.1369C4.92107 12.0481 4.90186 11.9526 4.90186 11.8561C4.90186 11.7597 4.92107 11.6642 4.95782 11.5754C4.99457 11.4866 5.04818 11.4065 5.11582 11.3395C6.62543 9.6455 7.9113 8.98485 9.22521 9.27197C9.8123 9.43268 10.3325 9.78574 10.7069 10.2772C10.9495 10.57 11.2437 10.8135 11.5737 10.995C12.2167 11.2821 13.223 11.4831 15.3197 10.2484C15.4774 10.1753 15.6559 10.1645 15.8207 10.2186C15.9854 10.2728 16.1248 10.3882 16.2117 10.5418C16.2987 10.6954 16.3269 10.8765 16.2912 11.0504C16.2556 11.2242 16.1585 11.3784 16.0186 11.4829C14.9769 12.1926 13.7717 12.6086 12.5241 12.6889Z"
      fill="#FCFCFD"
    />
  </svg>
);

export default OutsourcingTeamworkOriented;
